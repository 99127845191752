import axios from "../http";

/**
 * 获取禧币信息
 * @param
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getBalnace(params) {
  return axios.get("/wxmp/balance",{params});
}

export function getBalnaceList(params) {
  return axios.get("/wxmp/balance/list",{params});
}

export function withdraw(params) {
  return axios.post("/wxmp/balance/withdraw?amount="+params.amount,undefined);
}
