<template>
  <div class="page">
      <div class="balance">
        <div class="amount">
<!--          <div class="b-item">-->
<!--            <span class="label">总禧币</span><span class="num">{{ balnaceData.total}}</span>-->
<!--          </div>-->
          <div class="b-item">
            <span class="label">已到帐(可用)</span><span class="num">{{ balnaceData.balance }}</span>
          </div>
<!--          <div class="b-item">-->
<!--            <span class="label">未到帐(不可用)</span><span class="num">{{ balnaceData.frozenBalance }}</span>-->
<!--          </div>-->
          <div class="b-item">
            <span class="label">提现冻结</span><span class="num">{{ balnaceData.frostBalance }}</span>
          </div>
        </div>
        <div class="withdraw" v-if="user.level==45 || user.level==46" @click="showWithdraw=true">提现</div>
      </div>
    <div class="list" v-if="user.level==45 || user.level==46">
      <div class="item" v-for="(i,index) in balnaceList.list" :key="index">
        <div class="left">
          <img src="http://img.xinian727.cn/picture/f5a3d14e01c04cb19edb8d6f7bcad5b5-31cf7ecc92a742c391f0d3a099e559a43927359322030029135.png">
          <div>{{ i.status=='5'?'提现中':i.status=='6'?'成功':'失败' }}</div>
        </div>
        <div class="center">
          <div class="one">{{ i.orderNo }}</div>
          <div class="two">提现时间：{{ i.created }}</div>
        </div>
        <div class="right">
          <span>{{ i.amount }}</span> 元
        </div>
      </div>
    </div>
    <div class="list2" v-if="user.level!=45">非可提现用户</div>

    <van-dialog v-model="showWithdraw" title="填写提现金额" show-cancel-button @cancel="showWithdraw=false" @confirm="withdrawReq">
      <van-field v-model="money" label="提现金额" placeholder="请输入提现金额" type="number" />
      <div style="font-size:14px;color:red;text-align: center;padding: 6px 0px;">提现范围:50至200元,每天最多提现3次</div>
    </van-dialog>
  </div>
</template>

<script>
import {Dialog} from "vant";
import {getBalnace, getBalnaceList, withdraw} from '@/api/home'
export default {
  name: 'XiBi',
  data () {
    return {
      showWithdraw: false,
      money: undefined,
      user: {},
      params:{
        page: 1,
        size: 10,
        status: 5,
      },
      balnaceData:{
        balance:0,
        frostBalance: 0,
        frozenBalance: 0
      },
      balnaceList: {}
    }
  },
  mounted() {
    this.getBalnace()
    this.getBalnaceList()
    this.user = JSON.parse(sessionStorage.getItem('user'))
  },
  methods: {
    withdrawReq2(){
      console.log("========",this.money)
    },
    async withdrawReq(){
      let _this = this;
      console.log(this.money)
      if(!this.money){Dialog.alert({message: '请输入提现金额'});return}
      if(this.money<50){Dialog.alert({message: '最小提现金额50元'});return}
      if(this.money>this.balnaceData.balance){Dialog.alert({message: '提现额度不可大于可用额度'});return}
      if(this.money>200){Dialog.alert({message: '最大提现金额200元'});return}
      let d = await withdraw({amount:this.money});
      console.info("withdrawReq:",d)
      if (d){
        if (d>0){
          Dialog.alert({
            title: '提现成功',
            message: '请耐心等待管理员审核发放。',
            theme: 'round-button',
          }).then(() => {
            setTimeout(function() {
              _this.getBalnace()
              _this.getBalnaceList()
            }, 600);

          });
        }else {
          Dialog.alert({message: '提现失败'}).then(() => {});
        }
      }

    },
    async getBalnace() {
      this.balnaceData = await getBalnace(undefined);
      this.balnaceData['total'] = (this.balnaceData.balance+this.balnaceData.frozenBalance+this.balnaceData.frostBalance).toFixed(2)
    },
    async getBalnaceList() {
      let d = await getBalnaceList(this.params);
      this.balnaceList = d.list;
    },


  }
}
</script>

<style scoped lang="less">
.page{
  width: 750px;
  background: white;
  margin: 0 auto;
  padding: 0;
  .balance{
    height: 280px;
    background: url("http://img.xinian727.cn/picture/0d98f2fb77284b568a0ea27eb06f7ce8-df1c775c85954db497f104d97afc4eab870195682398947575.png") no-repeat;
    background-size: 100% 100%;
    .amount{
      width: 100%;
      height: 180px;
      display: flex;
      justify-content: space-around;
      color: white;
      font-size: 26px;
      .b-item{
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .num{
          margin-top: 15px;
        }
      }
    }

    .withdraw{
      margin: auto;
      width: 120px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      color: #fff;
      font-size: 26px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
    }
  }
  .list2{
    padding: 20px;
    text-align: center;
  }
  .list{
    min-height: 80vh;
    //padding: 20px;
    .item{
      display: flex;
      //justify-content: space-between;
      align-items: center;
      //height: 70px;
      padding: 20px 20px;
      border-bottom: 1px solid #eee;
      .left{
        width: 100px;
        text-align: center;
        img{
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
      }
      .center{
        padding-left: 10px;
        width: 69%;
        color: #999999;
        .two{
          margin-top: 10px;
        }
      }
      .right{
        width: 140px;
        text-align: right;
        font-size: 26px;
        span{
          color: #ff0000;
        }
      }
    }

  }
}
</style>
